@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Arcade Classic";
  src: url("./fonts/arcade-classic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --black: rgb(0, 0, 0);
  --darkest-gray: rgb(7, 15, 37);
  --darker-gray: #1c2541ff;
  --dark-gray: #2b3b56ff;
  --gray: #6081a9ff;
  --dark-green: rgb(52, 107, 99);
  --med-green: rgb(102, 223, 205);
  --green: rgb(133, 207, 196);
  --solid-green: rgb(62, 150, 120);
  --light-green: rgb(209, 240, 240);
  --red: rgb(110, 56, 56);
  --gray-green: rgb(61, 88, 84);
  --pink: rgb(255, 77, 164);
  --light-pink: rgb(224, 159, 191);
  --dark-pink: rgb(211, 37, 121);

  /* new colors */
  --light-gray: rgb(194, 194, 194);
  --blue: #2b5bb7;
}

html {
  background-color: var(--blue);
}

body {
  background-color: var(--blue);
  color: var(--light-gray);
  font-family: "Arcade Classic";
  line-height: 1;
}

a {
  color: var(--med-green);
}
a:hover {
  color: var(--pink);
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  font-family: Courier, monospace;
  color: var(--light-gray);
  background-color: var(--black);
  font-size: 1.4rem;
  font-weight: bold;
  border: 2px solid var(--gray);
  text-transform: uppercase;
  border-radius: 2px;
}

button:hover {
  background-color: var(--darker-gray);
  color: white;
  border: 2px solid var(--light-green);
}

hr {
  color: none;
  border: 1px solid var(--light-gray);
  margin: 1rem 1rem;
}

/* Keep pixelation by default for images*/
img {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* CSS3 */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
}

button:disabled,
button[disabled] {
  background-color: var(black) !important;
  color: var(--gray) !important;
  cursor: not-allowed;
  pointer-events: none;
  border: 2px solid var(--dark-gray) !important;
}

select {
  border: 2px solid var(--gray);
  background-color: var(--black);
  color: var(--light-gray);
  font-size: 1.3rem;
  width: 80%;
  max-width: 15rem;
  padding: 0.1rem 0.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input,
textarea {
  background-color: var(--black) !important;
  color: white !important;
  outline: none;
  border: 2px solid var(--gray);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.container {
  width: 92%;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1rem;
}

.cursed-text {
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  text-shadow: -4px 4px var(--med-green), 3px -2px var(--pink);
}

.card {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  background-color: var(--black);
  border: 2px solid var(--light-gray);
  font-size: 1.2rem;
  padding: 0.5rem 0.5rem;
}

.card > div {
  margin: 1rem 1rem;
}

.card-title {
  font-size: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--green);
  font-weight: bold;
}

.centered {
  text-align: center;
}

.white-text {
  color: white;
}

.header {
  font-size: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.header > .cursed-text {
  font-size: 4rem;
}

.bn-onboard-custom {
  text-transform: unset;
}

.two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.xmon-ticker {
  margin-left: 0.5rem;
  font-weight: bold;
}
.xmon-ticker {
  color: var(--med-green);
}

.nice-button {
  margin: 0.5rem 0.5rem;
  text-transform: none;
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  color: white;
  border: 3px solid var(--dark-gray);
  border-radius: 4px;
}
.nice-button:hover {
  border: 3px solid var(--gray);
}

@keyframes blinker {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  75% {
    opacity: 1;
  }
}

@keyframes colorFlash {
  25% {
    box-shadow: -3px 3px var(--med-green);
  }
  50% {
    box-shadow: -2px 3px var(--med-green), 1px -1px var(--pink);
  }
  75% {
    box-shadow: -1px 2px var(--med-green), 3px -2px var(--pink);
  }
}

.left-aligned {
  text-align: left;
  margin-left: 2rem;
}

@media only screen and (max-width: 768px) {
  .two-cols {
    display: block;
  }
  .form {
    width: unset !important;
  }
  .left-aligned {
    text-align: left;
    margin-left: 0.5rem;
  }
}
