table {
  border-spacing: 0;
  border: 1px solid white;
  font-size: 20px;
}

tr:last-child td {
  border-bottom: 0;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

th:last-child,
td:last-child {
  border-right: 0;
}
